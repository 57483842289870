<template>
  <div id="featured-slider">
    <div class="row">
      <div class="slider col-xs-12">
        <ul class="slider__wrapper">
          <li class="slider__item">
            <div id="slide1" class="box bg-cover"></div>
          </li>

          <li class="slider__item">
            <div id="slide2" class="box"></div>
          </li>

          <li class="slider__item">
            <div id="slide3" class="box"></div>
          </li>

          <li class="slider__item">
            <div id="slide4" class="box"></div>
          </li>

          <li class="slider__item">
            <div id="slide5" class="box"></div>
          </li>

          <li class="slider__item">
            <div id="slide6" class="box"></div>
          </li>

          <li class="slider__item">
            <div id="slide7" class="box"></div>
          </li>

          <li class="slider__item">
            <div id="slide8" class="box"></div>
          </li>

          <li class="slider__item">
            <div id="slide9" class="box"></div>
          </li>
        </ul>
      </div>
      <!-- end.slider -->
    </div>
    <!-- end.row -->
  </div>
  <!-- end#featured-slider -->

  <div id="section-feat" class="section">
    <div class="container">
      <div class="row">
        <div class="col-md-3 text-center grid">
          <p class="thumb"><i class="fa fa-home"></i></p>
          <h3>Residential Property</h3>
          <p>
            Let your residents live and sleep with a peace of mind knowing
            trained officers are looking out for their best interest.
          </p>
        </div>

        <div class="col-md-3 text-center grid">
          <p class="thumb"><i class="fa fa-suitcase"></i></p>
          <h3>Commercial / Retail Property</h3>
          <p>
            We will work directly with management and retail staff in order to
            reduce theft and property damage, showing customers that safety is a
            priority concern.
          </p>
        </div>

        <div class="col-md-3 text-center grid">
          <p class="thumb"><i class="fa fa-map-marker"></i></p>
          <h3>Events / Venues</h3>
          <p>
            Planning an event? Let our trained professionals help you organize
            the security and safety aspects and show a presence to attendees.
          </p>
        </div>

        <div class="col-md-3 text-center grid">
          <p class="thumb"><i class="fa fa-users"></i></p>
          <h3>Personal Protection</h3>
          <p>
            We provide formulated protection detail so that our clients can go
            about their social and business engagements in a secured space.
          </p>
        </div>
      </div>
    </div>
    <!-- end.container -->
  </div>
  <!-- end.section -->

  <div class="container">
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <h1 class="h4">SSI Protection Security Contractors in USA</h1>

        <p>
          Here at SSI Protection, we always strive to provide the best security
          services. We credit our accomplishments to proper management,
          supervision and training of our officers and the development and
          execution of sensible security programs. We maintain excellent
          communication with local law enforcement agencies that help us deliver
          a better service to our clients.
        </p>

        <p>
          We firmly believe that the training, selection and placement of our
          personnel are an integral part in producing superior customer service
          and quality. Security officers we provide are highly trained,
          licensed, bonded and competent in their duties.
        </p>

        <p>
          SSI Protection maintains liability insurance that far exceed the
          minimum levels required by
          <a href="http://www.dcjs.virginia.gov/" target="_blank"
            >DCJS (Dept. of Criminal Justice Services)</a
          >. A copy of the insurance certificate will be provided before
          commencing operations.
        </p>

        <p>
          As a licensed full-service security company, we maintain the proper
          staff documentation, training records, incident records and insurance
          records to provide our clients with the highest level of protection
          under the law and protect them against unjust litigation.
        </p>
      </div>
    </div>
    <!-- end.row -->
  </div>
  <!-- end.container -->
</template>

<script>
export default {
  name: "Home",
};
</script>
