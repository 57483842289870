<template>
  <div class="container">
    <div class="row">
      <h1 class="col-md-12 text-center page-title">404 - Page Not Found</h1>
    </div>
  </div>
  <!-- end.container -->

  <div class="container">
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <p>Page not found.</p>
      </div>
    </div>
    <!-- end.row -->
  </div>
  <!-- end.container -->
</template>

<script>
export default {
  name: "About",
};
</script>
