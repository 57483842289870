<template>
  <div class="container">
    <div class="row">
      <h1 class="col-md-12 text-center page-title">Contact Shasthra</h1>
    </div>
  </div>
  <!-- end.container -->

  <div class="container">
    <div class="row">
      <div class="col-md-6 col-md-offset-3">
        <div
          id="alert-success_contact"
          ref="alert-success_contact"
          class="hidden alert bg-success alert-form alert-form-success alert-dismissable fade in"
        >
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-hidden="true"
          >
            &times;
          </button>
          We've successfully received your message. We'll contact you soon!
        </div>

        <div
          id="alert-error_contact"
          class="hidden alert bg-danger alert-form alert-form-error alert-dismissable fade in"
        >
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-hidden="true"
          >
            &times;
          </button>
          Oops.. Something went wrong! Please try again later.
        </div>

        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label>Your Name <span class="req">*</span></label>
            <input
              id="input-name"
              class="form-control"
              type="text"
              required
              v-model="name"
            />
          </div>

          <div class="form-group">
            <label>Email Address <span class="req">*</span></label>
            <input
              id="input-email"
              class="form-control"
              type="email"
              required
              v-model="email"
            />
          </div>

          <div class="form-group">
            <label>Contact Number <span class="req">*</span></label>
            <input
              id="input-phone"
              class="form-control"
              type="text"
              required
              v-model="phone"
            />
          </div>

          <div class="form-group">
            <label>Enter Your Message <span class="req">*</span></label>
            <textarea
              id="input-message"
              class="form-control"
              rows="5"
              placeholder="What can we help you with ?"
              required
              v-model="message"
            />
          </div>

          <button
            id="btn-contactFormSubmit"
            class="btn btn-submit btn-block"
            type="submit"
            name="submit"
          >
            Send Your Message
          </button>
          <br class="visible-xs" /><br class="hidden-xs" />
        </form>

        <h4>Looking for job opportunities?</h4>

        <p>
          <a href="/docs/shasthra_employment-application.pdf" download
            ><u>Download Employment Application Form</u></a
          >
        </p>
        <br />
      </div>
    </div>
    <!-- end.row -->
  </div>
  <!-- end.container -->
</template>

<script>
const FORMSPARK_ACTION_URL = "https://submit-form.com/7t8Xa482z";

export default {
  name: "Contact",

  methods: {
    async submitForm() {
      await fetch(FORMSPARK_ACTION_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          Name: this.name,
          Email: this.email,
          Phone: this.phone,
          Message: this.message,
        }),
      })
        .then(function (response) {
          if (response.status == 200) {
            document
              .getElementById("alert-success_contact")
              .classList.remove("hidden");
          } else {
            document
              .getElementById("alert-error_contact")
              .classList.remove("hidden");
          }
        })
        .catch(function (response) {
          console.error(response);
          document
            .getElementById("alert-error_contact")
            .classList.remove("hidden");
        });
    },
  },
};
</script>
